<template>
  <div class="SetLine">
    <div class="flex">
      <div class="df-item">{{ type == 'set' ? '新增服务商' : '编辑服务商详情信息' }}</div>
    </div>
    <el-divider style="margin:12px 0 24px;"></el-divider>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">

      <div class="box">
        <div class="left">
          <div>

            <el-form-item label="服务商名称" prop="name">
              <el-input class="width-280" v-model="form.name" size="small" placeholder="请输入服务商名称"/>
            </el-form-item>

            <el-form-item label="线路模式" prop="mode">
              <el-select v-model="form.mode" class="line-el-select width-280" size="small" placeholder="请选择线路模式">
                <el-option v-for="item in modeList" :key="item.code" :label="item.remarks" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主叫敏感地区" prop="telAAreaIntercept">
              <el-input class="width-280" v-model="form.telAAreaIntercept" size="small"
                        placeholder="请填写主叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割"/>
            </el-form-item>
            <el-form-item label="被叫敏感地区" prop="telAAreaIntercept">
              <el-input class="width-280" v-model="form.telBAreaIntercept" size="small"
                        placeholder="请填写被叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割"/>
            </el-form-item>

            <el-form-item label="准入行业">
              <div class="flex-nowrap-flex-start">
                <el-button size="small" @click="addCharges1(0)">
                  <icon-font type="icon-tianjia"/>
                  添加准入行业
                </el-button>

              </div>
              <div class="button">
                <el-tag @close="deleteOptions(tag,i)" style="margin-right:20px"
                        v-for="(tag,i) in optionsNameArr"
                        :key="tag.name" closable>
                  {{ tag.name }}
                </el-tag>
              </div>

            </el-form-item>

            <el-form-item label="禁入行业">
              <div class="flex-nowrap-flex-start">
                <el-button size="small" @click="addCharges1(1)">
                  <icon-font type="icon-tianjia"/>
                  添加禁入行业
                </el-button>

              </div>
              <div class="button">
                <el-tag @close="deleteForbiddenOptions(tag,i)" style="margin-right:20px"
                        v-for="(tag,i) in forbiddenOptionsNameArr"
                        :key="tag.name" closable>
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-form-item>

          </div>

          <div class="buttonli">
            <el-button class="button-left" @click="resetForm('form')">取消</el-button>
            <el-button class="button-right" @click="submitForm('form')">{{
                type == 'set' ? '确认' : '保存'
              }}
            </el-button>
          </div>

        </div>

        <div class="right">

          <el-form-item label="小号月租" prop="monthlyRent">
            <div class="flex-nowrap-flex-start">
              <div class="el-input-width pl-20">
                <el-input type="number" v-model="form.monthlyRent" size="small" placeholder="金额"></el-input>
              </div>
              <div class="unit-box">元/个</div>
            </div>
          </el-form-item>
          <el-form-item label="是否报备" prop="">
            <el-switch size="mini" active-text="是" inactive-text="否" class="m-l-10 m-r-10" inline-prompt
                       v-model="form.report" :active-value="1" :inactive-value="0" @click="$forceUpdate">
            </el-switch>
          </el-form-item>

          <el-form-item label="并发限制">
            <div class="flex-nowrap-flex-start">
              <div>1</div>
              <div class="num-input">:</div>
              <div class="num-input">
                <el-input-number v-model="form.concurren" :min="1" :max="300" size="small" controls-position="right"
                                 @change="handleChange"/>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="资费设置">
            <div class="flex-nowrap-flex-start">
              <el-button size="small" @click="addCharges">
                <icon-font type="icon-tianjia"/>
                添加更多资费模式
              </el-button>
              <div class="icon-help-box">
                <icon-font type="icon-bangzhu1" class="icon-help"/>
                <div class="icon-help-text">
                  <div class="triangle"></div>
                  <div>帮助：点击添加按钮即可添加多条资费模式（例：含录音模式资费）</div>
                </div>
              </div>
            </div>
            <div v-for="(option,o) in form.options" :key="o">
              <div class="flex-nowrap-flex-start">
                <div class="buy-name">资费模式{{ o + 1 }}</div>
                <div v-show="o != 0" class="delete-text" @click="deleteItem(o)">删除</div>
              </div>
              <el-form ref="option" :model="option" :rules="rules" label-width="100px">
                <el-form-item label="通话资费">
                  <div class="flex-nowrap-flex-start">
                    <div class="el-input-width pl-20">
                      <el-input v-model="form.options[o].basic" size="small" placeholder="金额"
                                @input="getNameValue(form.options[o].basic,null,null,o)"></el-input>
                    </div>
                    <div class="unit-box">元/分钟</div>
                  </div>
                </el-form-item>
                <el-form-item label="其他资费">
                  <div class="el-input-width ">
                    <el-select v-model="option.surcharge" multiple size="small" collapse-tags style="margin-left: 20px"
                               placeholder="选择其他资费">
                      <el-option v-for="item in surchargeList" :key="item.name" :label="item.value" :value="item">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="flex-nowrap-flex-start" v-for="(item,i) in option.surcharge" :key="i">
                    <div class="el-input-width pl-20">
                      <el-input v-model="option.surcharge[i][item.name]"
                                @input="getNameValue(form.options[o].basic,option.surcharge,option.surcharge[i],o)"
                                size="small">
                        <template #prepend>{{ item.value }}</template>
                      </el-input>
                    </div>
                    <div class="unit-box">元/分钟</div>
                  </div>
                </el-form-item>
                <el-form-item label="最终费用">
                  <div class="flex-nowrap-flex-start">
                    <div class="pl-20">
                      {{ form.options[o].final == '' ? '0' : form.options[o].final }}
                    </div>
                    <div class="unit-box">元/分钟</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-form-item>
          <el-form-item label="线路详情" prop="description">
            <Wangeditor class="width-280"
                        v-model="form.description" :content="form.description"
                        @inputs="getWangEditorValue"></Wangeditor>
          </el-form-item>

        </div>
      </div>
    </el-form>

    <div>
      <el-dialog v-model="centerDialogVisible"
                 :title="'选择'+(isHandleForbidden?'禁入':'准入')+'行业'"
                 :close-on-click-modal="false"
                 :destroy-on-close="true" width="700px" height="500px">

        <el-row gutter="20">
          <el-col span="12">
            <el-cascader :props="props" filterable
                         :placeholder="'选择/搜索'+(isHandleForbidden?'禁入':'准入')+'行业'"
                         :value="value" v-model="value3"
                         :options="industryTreeOption"></el-cascader>
          </el-col>
          <el-col span="12">
            <div class="dialogfooter">

              <el-button class="cancel" @click="handleDialogCancel">取消</el-button>
              <el-button class="comfirm" @click="handleDialogComfirm">确定</el-button>

            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {IconFont} from '@/utils/iconfont'
import Wangeditor from '@/components/Wangeditor.vue'
import dictionary from '@/api/system/dictionary'
import amount from '@/api/open/amount/amount'

export default {
  name: 'SetLine',
  components: {
    IconFont,
    Wangeditor,
  },
  data() {
    return {
      label: [],
      industryList: [],
      type: '',
      optionsNameArr: [],
      forbiddenOptionsNameArr: [],
      centerDialogVisible: false,
      isHandleForbidden: false,//是否在操作禁入行业
      props: {
        multiple: true,
        checkStrictly: true,//Select any level of options
      },
      value: '',
      value3: [],
      industryTreeOption: [],
      form: {
        report: '',
        name: '',
        monthlyRent: '',
        concurren: 1,
        options: [{basic: '', surcharge: [], final: ''}],
        mode: '',
        description: '',
        telAAreaIntercept: '',
        telBAreaIntercept: '',
        industryIds: '',//准入行业
        unindustryIds: '',//禁入行业
      },
      modeList: [],
      surchargeList: [],
      value1: [],
      repeatClick: true,
      content: '',
      rules: {
        name: [
          {
            required: true,
            message: '请填写服务商名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 100,
            message: '名称长度不超过100个字符',
            trigger: 'blur',
          },
        ],
        mode: [
          {
            required: true,
            message: '请选择线路模式',
            trigger: 'blur',
          },
        ],
        monthlyRent: [
          {
            required: true,
            message: '请填写月租',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '请填写详情',
            trigger: 'blur',
          },
        ],
        telAAreaIntercept: [
          {
            required: false,
            message:
                '请填写主叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割',
            trigger: 'blur',
          },
        ],
        telBAreaIntercept: [
          {
            required: false,
            message:
                '请填写被叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割',
            trigger: 'blur',
          },
        ],
      },
      arr: [{record: '0.4'}],
    }
  },

  async mounted() {
    console.log('this.$route.query.id', this.$route.query.id);
    if (this.$route.query.id == 'set') {
      //新增服务商
      await this.getModeList(this.$route.query.id)
    } else {
      //编辑服务商
      await this.getModeList(this.$route.query.id)
      this.getAmountDetails(this.$route.query.id)
    }
    this.type = this.$route.query.id
  }
  ,
  methods: {
    getWangEditorValue(val) {
      // console.log(val)
      this.form.description = val
    }
    ,
    resetForm() {
      this.$router.push({
        path: '/line/SetLine',
      })
    }
    ,

    // 删除标签
    deleteOptions(tag, i) {
      if (this.optionsNameArr.indexOf(tag.id) != -1) {
        this.optionsNameArr.splice(this.optionsNameArr.indexOf(tag.id), 1)
      }
      this.optionsNameArr.splice(i, 1)

      let a = []
      this.optionsNameArr.forEach((e) => {
        a.push(e.id)
      })
      this.form.industryIds = a.join()

      // if (tag.optionsId != 60) {
      //   this.checkList.forEach((item, i) => {
      //     this.handleCheckedCitiesChange(i)
      //   })
      // } else {
      //   this.valueArr = null
      // }
    }
    ,
    // 删除标签
    deleteForbiddenOptions(tag, i) {
      if (this.forbiddenOptionsNameArr.indexOf(tag.id) != -1) {
        this.forbiddenOptionsNameArr.splice(this.forbiddenOptionsNameArr.indexOf(tag.id), 1)
      }
      this.forbiddenOptionsNameArr.splice(i, 1)

      let a = []
      this.forbiddenOptionsNameArr.forEach((e) => {
        a.push(e.id)
      })
      this.form.unindustryIds = a.join()
    }
    ,

    submitForm(formName) {
      if (this.repeatClick == true) {
        this.repeatClick = false
        this.$refs[formName].validate((valid) => {

          // console.log(valid, '777777777777777777777777777777777777')
          if (valid) {
            if (this.type == 'set') {
              if (this.form.monthlyRent >= 0) {
                amount
                    .amountAdd({
                      name: this.form.name,
                      monthlyRent: this.form.monthlyRent,
                      concurren: this.form.concurren,
                      options: JSON.stringify(this.form.options),
                      mode: this.form.mode,
                      description: this.form.description,
                      report: this.form.report,
                      telAAreaIntercept: this.form.telAAreaIntercept,
                      telBAreaIntercept: this.form.telBAreaIntercept,
                      industryIds: this.form.industryIds,
                      unindustryIds: this.form.unindustryIds,

                    })
                    .then((res) => {
                      this.$notify({
                        title: '成功',
                        message: '服务商新增成功！',
                        type: 'success',
                      })
                      this.repeatClick = true
                      this.$emit('addSuccess', 'ok')

                      this.$router.push({
                        path: '/line/SetLine',
                      })
                    })
                    .catch((err) => {
                      this.repeatClick = true
                    })
              } else {
                this.$notify({
                  title: '失败',
                  message: '小号月租不能为负',
                  type: 'error',
                })
                this.repeatClick = true
              }
            } else {
              if (this.form.monthlyRent >= 0) {
                amount
                    .amountEdit({
                      name: this.form.name,
                      monthlyRent: this.form.monthlyRent,
                      concurren: this.form.concurren,
                      options: JSON.stringify(this.form.options),
                      mode: this.form.mode,
                      id: this.form.id,
                      description: this.form.description,
                      report: this.form.report,
                      telAAreaIntercept: this.form.telAAreaIntercept,
                      telBAreaIntercept: this.form.telBAreaIntercept,
                      industryIds: this.form.industryIds,
                      unindustryIds: this.form.unindustryIds,
                    })
                    .then((res) => {
                      this.$notify({
                        title: '成功',
                        message: '服务商修改成功！',
                        type: 'success',
                      })

                      this.$router.push({
                        path: '/line/SetLine',
                      })
                      this.getAmountDetails(this.$route.query.id)

                      this.repeatClick = true
                      this.$emit('addSuccess', 'ok')
                    })
                    .catch((err) => {
                      this.repeatClick = true
                    })
              } else {
                this.$notify({
                  title: '失败',
                  message: '小号月租不能为负',
                  type: 'error',
                })
                this.repeatClick = true
              }
            }
          } else {
            this.$notify({
              title: '注意',
              message: '请选择或填写必填项',
              type: 'warning',
            })
            this.repeatClick = true
            return false
          }
        })
      } else {
        this.$notify({
          title: '注意',
          message: '请不要重复提交，服务商正在创建中！',
          type: 'warning',
        })
      }
    }
    ,

    handleDialogCancel() {

      this.centerDialogVisible = false
      // this.getAmountDetails(this.$route.query.id)

    }
    ,
    handleDialogComfirm() {
      this.centerDialogVisible = false
      let a = []
      console.log(
          JSON.parse(JSON.stringify(this.value3)),
          '88888888888888888888888bbbbbbbbbbbbbbbbbbbbbbbbb==================='
      )
      JSON.parse(JSON.stringify(this.value3)).forEach((e) => {
        a.push(e[e.length - 1])
      })
      let l = []
      this.industryList.forEach(e => {
        a.forEach(v => {
          if (e.id == v) {
            l.push(e)
          }

        })
      })

      if (this.isHandleForbidden) {
        if (l && this.optionsNameArr) {
          let sameList = this.optionsNameArr.filter(option => {
            return l.findIndex(current => current.id == option.id) !== -1;
          })
          if (sameList && sameList.length > 0) {
            this.$message.error(`已有${sameList.length}个行业在准入行业列表中`);
            l = l.filter(current => {
              return sameList.findIndex(option => option.id == current.id) === -1;
            })
            a = a.filter(current => {
              return sameList.findIndex(option => option.id == current) === -1;
            })
            // return;
          }
        }
        this.forbiddenOptionsNameArr = l
        this.form.unindustryIds = a.join()
      } else {
        if (l && this.forbiddenOptionsNameArr) {
          let sameList = this.forbiddenOptionsNameArr.filter(option => {
            return l.findIndex(current => current.id == option.id) !== -1;
          })
          if (sameList && sameList.length > 0) {
            this.$message.error(`已有${sameList.length}个行业在禁入行业列表中`);
            //可以去重后赋值，也可以直接返回
            l = l.filter(current => {
              return sameList.findIndex(item => item.id == current.id) === -1;
            })
            a = a.filter(current => {
              return sameList.findIndex(option => option.id == current) === -1;
            })
            // return;
          }
        }
        this.optionsNameArr = l;//结构：{id,name,createTime}
        this.form.industryIds = a.join()
        // console.log('handleDialogComfirm optionsNameArr', this.optionsNameArr)
        // console.log('handleDialogComfirm industryIds', this.form.industryIds)
      }

    }
    ,
    // 删除元素
    deleteItem(o) {
      if (o != 0) {
        this.form.options.splice(o, 1)
      }
    }
    ,

    addCharges1(forbidden) {
      this.isHandleForbidden = forbidden;
      ////optionsNameArr结构跟industryList一致：{pid,id,name,...},pid为0代表一级行业
      if (!forbidden && this.optionsNameArr) {
        this.value3 = []
        this.optionsNameArr.forEach((e, v) => {
          let item = [];
          if (e.pid) {
            item.push(e.pid);
          }
          item.push(e.id);
          this.value3.push(item)
          // this.value3.push([e.pid, e.id])
        })
      } else if (forbidden && this.forbiddenOptionsNameArr) {
        this.value3 = []
        this.forbiddenOptionsNameArr.forEach((e, v) => {
          let item = [];
          if (e.pid) {
            item.push(e.pid);
          }
          item.push(e.id);
          this.value3.push(item)
          // this.value3.push([e.pid, e.id])
        })
      }


      this.centerDialogVisible = true
      console.log(this.value3, '888888888888888888888=======================================')

    }
    ,
    addCharges() {
      this.form.options.push({})
    }
    ,
    //获取服务商详情
    getAmountDetails(id) {
      // amount
      //     .industryid({
      //       id: id,
      //     })
      //     .then((res) => {
      //       this.optionsNameArr = res.data
      //
      //
      //       console.log(
      //           'res :>>33333333333333333333333333333333333333333333 ',
      //           this.value3
      //       )
      //     })

      amount
          .amountGet({
            id: id,
          })
          .then((res) => {
            console.log('res :>> ', res)
            let detail = res?.data;
            this.form.name = detail?.name
            this.form.telAAreaIntercept = detail?.telAAreaIntercept
            this.form.telBAreaIntercept = detail?.telBAreaIntercept

            this.form.monthlyRent = detail?.monthlyRent
            this.form.concurren = detail?.concurren
            this.form.mode = detail?.mode
            this.form.description = detail?.description
            this.form.options = JSON.parse(detail?.options)
            this.form.id = detail?.id
            this.form.report = detail?.report

            this.optionsNameArr = [];//结构：{id,name,createTime}
            this.form.industryIds = detail?.industryIds;

            this.forbiddenOptionsNameArr = []
            this.form.unindustryIds = detail?.unindustryIds;

            let industryIdList = this.form?.industryIds?.split(',') || [];
            let unindustryIdList = this.form?.unindustryIds?.split(',') || [];

            this.industryList.forEach(e => {
              industryIdList.forEach(v => {
                if (e.id == v) {
                  //optionsNameArr结构跟industryList一致：{pid,id,name,...},pid为0代表一级行业
                  this.optionsNameArr.push(e)
                }
              })
              unindustryIdList.forEach(v => {
                if (e.id == v) {
                  this.forbiddenOptionsNameArr.push(e)
                }
              })
            })

            // console.log('industryIdList ', industryIdList, unindustryIdList, this.forbiddenOptionsNameArr)
          })
    },
    setBaseTree(data, returnData) {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children.length != 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
            })
          }

          if (v.children) {
            this.setBaseTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      // console.log(returnData,'returnDatareturnDatareturnDatareturnData')
      // return returnData;
      return returnData;
      // console.log(returnData, 'returnDatareturnDatareturnData')
    },
    getIndustryFromTree(tree, prop, id) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i][prop] == id) {
          return tree[i];
        }
        if (tree[i].children && tree[i].children.length > 0) {
          let find = this.getIndustryFromTree(tree[i].children, prop, id);
          if (find) {
            return find;
          }
        }
      }
      return null;
    },
    setIndustryList(e) {
      let res = [];
      e.forEach(first => {
        res.push({
          amountId: first.amountId,
          id: first.id,
          name: first.name,
          pid: first.pid,
        })
        if (first.children && first.children.length > 0) {
          first.children?.forEach(second => {
            res.push({
              amountId: second.amountId,
              id: second.id,
              name: second.name,
              pid: second.pid,
            })
            if (second.children && second.children.length > 0) {
              second.children?.forEach(third => {
                res.push({
                  amountId: third.amountId,
                  id: third.id,
                  name: third.name,
                  pid: third.pid,
                })
              })
            }
          })
        }
      })
      return JSON.parse(JSON.stringify(res));
    },

    // 获取线路模块表
    async getModeList(amountId) {
      let amountIds = [0];
      if (amountId && amountId != 'set') {
        amountIds.push(amountId);
      }
      let industryRes = await dictionary.industry({amountIds: amountIds});
      let arr = []
      this.industryTreeOption = this.setBaseTree(industryRes?.data, arr);
      this.industryList = this.setIndustryList(industryRes?.data);

      let modeRes = await dictionary
          .queryName({
            type: 'mode',
          });
      this.modeList = modeRes?.data || [];

      let surchargeRes = await dictionary
          .query({
            type: 'surcharge',
          });
      this.surchargeList = surchargeRes?.data || [];
      this.surchargeList.forEach((item, i) => {
        this.surchargeList[i][item.name] = 0
      })
    },
  },
}
</script>
<style lang="scss" scoped>

.buttonli {
  display: flex;
  position: fixed;
  left: 240px;
  bottom: 80px;
}

.button {
  margin-top: 20px;
  //margin-left: 10px;
}

.width-280 {
  z-index: 0;
}

:deep(.el-overlay) {
  z-index: 999999999991;
  background-color: rgba(0, 0, 0, 0);
}

.box {
  display: flex;

  .left {
    display: flex;
    width: 35%;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    width: 60%;
    flex-direction: column;
  }
}

.width-280 {
  width: 380px;
}

.line-el-select {
  width: 80%;
}

.num-input {
  margin-left: 16px;
}

.icon-help-box {
  margin-left: 20px;
  cursor: pointer;
  position: relative;

  .icon-help {
    font-size: 18px;
  }

  .icon-help-text {
    display: none;
  }
}

.icon-help-box:hover .icon-help-text {
  display: block;
  position: absolute;
  top: 45px;
  left: -13px;
  z-index: 20;
  width: 240px;
  padding: 16px;
  border-radius: 0.5rem;
  opacity: 0.75;
  background: #000000;
  box-shadow: 0px 3px 28px 0px rgba(0, 0, 0, 0.1);
}

// 三角形
.triangle {
  position: absolute;
  top: -12px;
  left: 15px;
  border-style: solid;
  border-width: 0px 8px 12px 8px;
  opacity: 1.52;
  // background: #000000;
  border-color: transparent transparent #000000 transparent;
  width: 0px;
  height: 0px;
}

.el-input-width {
  width: 220px;
}

.unit-box {
  margin-left: 9px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.rolling-box {
  height: 450px;
  padding-right: 16px;
  overflow: overlay;
}

.button-left {
  color: #333333;
  background: #f2f3f8;
}

.button-right {
  color: #ffffff;
  background: #637dff;
}

.delete-text {
  margin-left: 30px;
  cursor: pointer;
  color: #f56c6c;
}

.button-box {
  background: #fff;
  height: 60px;
}

.buy-name {
  font-size: 14px;
  font-weight: 700;
  color: #637dff;
}

.width-280 {
  width: 380px;
}

.m-l-10 {
  margin-left: 10px !important;
  color: #909399;
}

.m-t-10 {
  margin-top: 10px !important;
}

::v-deep(.div [class*='el-icon-'] + span) {
  margin: 0;
}

.comfirebtn {
  float: left;
  margin: 0 0 0 10px;
  width: 80px;
  height: 32px;
  background: #637dff;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}

.backbtn {
  float: left;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;

  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}

.SetLine {
  padding-right: 1.125rem;
}

.lineCard-show-box {
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow-y: overlay;
}

.type-name {
  width: 80px;
}

.line-in-button {
  width: 100%;
}

.button-left {
  width: 100%;
  color: #333333;
  background: #f2f3f8;
}

.button-right {
  width: 100%;
  color: #ffffff;
  background: #637dff;
}

.font-refresh-16 {
  font-size: 14px;
  color: #637dff;
}

.df-item {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #09132d;
  letter-spacing: 0px;
  margin: 0 20px;
  cursor: pointer;

  &.active {
    color: #637dff;
  }
}

.flex {
  display: flex;

  &.center {
    align-items: center;
  }

  &.mt {
    margin-top: 10px;
  }
}

.text-gray {
  color: #86909c;
}

.text-black {
  color: #000;
}

.configtext {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #09132d;
}

.append-margin {
  margin-top: 15px;
}

::v-deep(.header-row th) {
  background: #eeeef3;
}

.button-text-edit {
  color: #637dff;
}

.button-text-del {
  color: #ff5757;
}

.noconfig-context {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .noconfig-img {
    width: 113px;
    margin-top: 180px;
  }

  .noconfig-text {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #86909c;
    margin-top: 28px;
  }

  .noconfig-btn {
    width: 108px;
    height: 32px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
}

.dialogfooter {
  display: flex;
  flex-direction: row-reverse;
  // justify-content: space-around;
  //  align-items: center;
  .cancel {
    width: 94px;
    height: 36px;
    background: #eeeeee;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #131523;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .comfirm {
    margin-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 36px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    letter-spacing: 0px;
  }
}

.model_url {
  margin-left: 10px;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>